// -----------------------------------------------------------------------------
.content-box--carpet {
  height: 100%;
  position: relative;

  .carpet {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: $footer-toggle-height;

    //do not use this, reto says it has performance issues
    //supress pointer event to make it click throu, but see below
    //pointer-events: none;
  }

  .carpet-item {
    //default hidden, use visibility otherwise dimensions will not calculate
    //corectly in carpet.js
    opacity: 0;
    position: absolute;

    //do not use this, reto says it has performance issues
    //reset pointer event, otherwise children inherit the supressed state
    //pointer-events: auto;

    //hack to prevent pixelated images
    transform: scale(1) translateZ(0);
    transform-origin: top left;
    transition: all 0.2s linear;

    img {
      max-width: none;

      //prevent pixelated images
      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
    }

    .caption {
      //make it absolute, so it resides in the visuals padding
      position: absolute;
      top: 1.5 * $base-line;
      left: 1.5 * $base-line;
      //bottom: 0;

      //has to be full width
      //width: 100%;
      width: auto;
      padding: 0.25 * $base-line;
      background-color: rgba(255, 255, 255, 0.8);

      //the trick is to fix this to a 2 liner, this solves all problems with
      //responsivness
      //height: rem-calc($gallery-caption-height);

      //styling
      text-align: left;
      @extend %font-body-regular;
      font-size: rem-calc(14);
      color: $secondary-color;

      p {
        margin-bottom: 0;
        line-height: 1.4;
        font-size: 0.875em;
      }
    }

    .btn-close {
      position: absolute;
      right: $base-line;
      top: $base-line;

      @extend .kn-icon-tearsheet-close;
      display: block;

      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
      cursor: pointer;
    }

    &.thumb {
      /*
      transform: scale($carpet-scale-factor) translateZ(0);

      &:active {
        transform: scale($carpet-scale-factor * 1.05) translateZ(0);
      }
      */

      .btn-close,
      .caption {
        display: none;
      }
    }
  }
}
