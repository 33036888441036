@import "../app-settings";

// -----------------------------------------------------------------------------
// Standard Gallery

//OVERRIDE app settings
$gallery-content-box-max-height: 650px;

.content-box--gallery-slider {
  height: 100%;

  //limit the max height
  max-height: $gallery-content-box-max-height;

  //dont let it go to the left and right borders
  //margin: 0 20px;

  .gallery-slider {
    height: 100%;
    //max-width: 800px;

    //center it
    margin: 0 auto;

    @if $DEBUG == true {
      border: 1px solid green;
    }
  }

  .gallery-cell {
    //stretch full
    height: 100%;
    width: 100%;

    .flex-img-wrapper,
    .flex-tearsheet-wrapper {
      img {
        position: absolute;
        display: block;
        max-width: 100%;
        max-height: 100%;
        left: 0;
        right: 0;
        //top: 0;
        bottom: rem-calc($gallery-caption-height);
        padding-top: rem-calc($gallery-caption-height);
        margin: auto;
      }

      .tearsheet-toggle {
        position: absolute;
        display: block;
        max-width: 100%;
        max-height: 100%;
        left: 0;
        right: 0;
        //top: 0;
        //bottom: rem-calc($gallery-caption-height);
        //padding-top: rem-calc($gallery-caption-height);
        margin: auto;
        z-index: 2;

        &:after {
          content: "";
          position: absolute;
          right: 15px;
          top: 15px;
          @extend .kn-icon;
          @extend .kn-icon-tearsheet-open;
        }

        &.is-open:after {
          @extend .kn-icon-tearsheet-close;
        }
      }
    }

    .flex-tearsheet-wrapper {
      display: none;
    }

    .visual {
      //the trick is to make it 100% and then pad the caption on the bottom
      position: relative;
      height: 100%;
      padding-bottom: rem-calc($gallery-caption-height);

      text-align: center;

      @if $DEBUG == true {
        background: grey;
      }
    }

    .caption {
      //make it absolute, so it resides in the visuals padding
      position: absolute;
      bottom: 0;

      //has to be full width
      width: 100%;

      //the trick is to fix this to a 2 liner, this solves all problems with
      //responsivness
      //2pixel change request
      height: rem-calc($gallery-caption-height - 2px);
      margin-top: 2px;

      //styling
      text-align: center;
      @extend %font-body-regular;
      font-size: rem-calc(14);
      color: $secondary-color;

      p {
        margin-bottom: 0;
        line-height: 1.4;
        font-size: 0.875em;
      }

      @if $DEBUG == true {
        background: yellow;
      }
    }
  }
}

//if we need fade instead of slide, consider this:
//https://github.com/metafizzy/flickity/issues/26
/*
.flickity-slider{ transform: none !important; }
.slider__item{
    left: 0 !important;
   opacity: 0;
   z-index: -1; //see comments
   transition: "opacity 0.3s ease-in-out";
}
.slider__item.is-selected{
    opacity: 1;
    z-index: 0; //see comments
}
*/
