.content-box--static-page {
  display: flex;
  min-height: 100%;
}

.text-block {
  flex: 1;
  height: 100%;
}
.content-box--static-page,
.text-block {
  position: relative;
  display: flex;
  flex-direction: column;

  p {
    margin-bottom: 0;
    font-size: rem-calc(14);
    line-height: 1.5;
  }

  .image-block {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 30%;
    max-width: 100%;
  }

  .text-block {
    margin-left: 38%;

    @include breakpoint(large up) {
      position: relative;
      z-index: 100;
    }
  }

  .top-block {
  }

  .bottom-block {
    padding-top: 2 * $base-line;
    margin-top: auto;
  }
}

@include breakpoint(medium up) {
  .content-box--static-page {
    margin-right: 30px;
  }
}
