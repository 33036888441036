//OVERRIDE app settings
$gallery-content-box-max-height: 550px;

.content-box.tv-special {
  transform: scale(1);
  transform-origin: 63% 44%;

  .portrait & {
    transform-origin: 63% 33%;
  }

  &.zoomed {
    transform: scale(3.8);
  }
}

.tv-skin {
  user-select: none;
}

.content-box--tv {
  height: 100%;

  //limit the max height
  //max-height: $gallery-content-box-max-height;

  .content-box--tv-wrapper {
    height: 100%;
    position: relative;
    text-align: center;

    img {
      //IMPORTANT override foundation or cells will collapse
      position: relative;
      max-width: 100%;
      max-height: 100%;

      //the transparent has to be filled black on init until js starts
      z-index: 1;

      background-color: none;
      transition: all 0.5s linear;

      //TODO only working in IE 11!
      pointer-events: none;
    }

    .video-injector {
      position: absolute;
      top: 0;
      margin: 0 auto;

      //hide it by default, js will enable it:
      width: 0;
      height: 0;

      background-color: rgba(125, 1, 1, 0.5);
      z-index: 0; //behind
    }

    @if $DEBUG == true {
      border: 1px solid green;
    }
  }

  //special for canvas
  &.tv-skin--tv_skin_canvas {
    .video-injector {
      z-index: 3;
    }
  }
  &.tv-skin--tv_loewe {
    .video-injector {
      z-index: 3;
    }
  }

  //special settings for skins
  &.tv-skin--tv_skin_special_super8 {
    cursor: pointer;

    .video-injector {
      z-index: 3;
    }
  }

  &.tv-skin--tv_skin_special_outdoor {
    overflow: hidden;

    img {
      max-height: none;
    }

    .video-injector {
      z-index: 3;
    }
  }
}

//global as it will be reattached
//super 8 close btn-close
.super8-btn-close {
  //hide on non super 8 tv pages
  //this will also select the reparented button (to header), but as it's
  //shown and hidden via tv.js, it's ok to hide it initally anyway
  :not(.tv-special) & {
    display: none;
  }

  position: absolute;

  //same as menu button
  right: 94px;

  @extend .kn-icon-close;
  display: block;

  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;

  z-index: 20;

  &:focus {
    outline: 0;
  }
}

.lightsoff-overlay {
  position: absolute;
  top: -1000px;
  right: 0;
  bottom: -1000px;
  left: 0;
  background: rgba(#000, 0.7);
  z-index: 2;
  pointer-events: none;
  transition: all 0.5s linear;

  &.hidden {
    opacity: 0;
  }
}
