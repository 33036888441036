// all overrides for small galleries
@include breakpoint(small only) {
  // ---------------------------------------------------------------------------

  .header {
    //visual storytelling addon
    height: $header-height-smallscreens !important;

    //visual storytelling addon
    .header-head {
      h1 {
        margin-bottom: -0.75 * $base-line !important;
        display: block !important;
      }
      h3 {
        margin-bottom: 0.1 * $base-line !important;
      }
    }

    .header-project {
      display: block;

      .delimiter {
        display: none;
      }
    }

    //on small screens leave this button up in the corner
    .nav-toggle {
      z-index: 101;
      position: fixed;
      right: 0;
      /*
      position: -webkit-sticky;
      position: sticky;
      */
    }

    .kn-icons {
      //transform: scale3d(0.75, 0.75, 1);
      //transform-origin: 100% 30%;
      z-index: 101; /* transform creates a new stacking context, z-index necessary here too */

      .kn-icon {
        text-indent: -9999px;
      }

      .kn-icon-menu {
        margin-right: 15px;
      }

      .kn-icon-close {
        position: fixed;
        z-index: 80;
        right: 60px;
      }
    }
    /*
    @media screen and (max-width: 639px) {
      .kn-icons .kn-icon-close { display: none !important; }
    }
    */
  }

  .site-content {
    //give on mobile more space for below
    padding-bottom: $footer-toggle-height;
  }

  // this streches to container to 100% inside the flexbox child
  .content-box {
    //overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 0 10px 0 10px;
  }

  footer {
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 1000;
  }

  .footer {
    .description {
      width: auto;
      margin-bottom: 0.5 * $footer-toggle-height;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      max-height: 50vh;
    }
  }

  .util-nav {
    .kn-icon {
      z-index: 95 !important;
      transform: translateZ(1px);
    }

    .shariff .shariff-button {
      .fa {
        margin-top: -2px;
        margin-left: -2px;

        &.fa-facebook {
          margin-left: -3px;
        }
        &.fa-linkedin {
          margin-top: -2px;
        }
      }
    }
  }

  //////////////////////////////////////////////////////////////////////////////
  // Citation
  .content-box--citation {
    display: none;
  }

  //////////////////////////////////////////////////////////////////////////////
  // Project slider
  .content-box--project-slider {
    height: 100%;

    margin-left: -10px;
    margin-right: -10px;

    .slider-cell {
      img {
        margin: 0 auto;
      }

      .label {
        padding-left: 10px;
        position: relative;
        display: inline-block;
      }

      //trick: we have to assing it to the last label because of the setup
      &:last-child .label {
        margin-bottom: 2 * $base-line;
      }
    }
  }

  //////////////////////////////////////////////////////////////////////////////
  // Timestrip
  .time-strip {
    display: none;
  }

  //////////////////////////////////////////////////////////////////////////////
  // Gallery
  .content-box--gallery-slider,
  .content-box--gallery-index {
    .visual {
      margin-top: $base-line !important;
      padding-bottom: 0 !important;
    }

    .flex-img-wrapper,
    .flex-tearsheet-wrapper {
      img {
        position: relative !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        bottom: auto !important;
      }
    }

    .flex-tearsheet-wrapper {
      margin-top: $base-line !important;
      display: block !important;
    }

    .caption {
      position: relative !important;
      bottom: auto !important;
      //display: block !important;
      height: auto !important;
      //padding-bottom: $base-line !important;
      text-align: left !important;

      &.caption-tearsheet {
        display: block !important;
      }
    }

    .tearsheet-toggle,
    .tearsheet-toggle:after {
      display: none !important;
    }
  }

  .content-box--gallery-slider {
    .gallery-cell:last-child {
      margin-bottom: 1.5 * $footer-toggle-height;
    }
  }

  .content-box--gallery-index-thumbs {
    display: none;
  }

  //////////////////////////////////////////////////////////////////////////////
  // STRIP
  .content-box--gallery-strip {
    .gallery-cell {
      img {
        max-width: 100%;
      }

      .visual {
        margin-top: $base-line !important;
        padding-bottom: 0 !important;
      }

      .caption {
        position: relative !important;
        height: auto !important;
        //margin-bottom: 1.5 * $base-line !important;
        //same as gallery
        margin-botton: 0 !important;
      }

      &:first-child {
        padding-left: 0px !important;

        //override
        .visual {
          margin-top: 0 !important;
        }
      }

      &:last-child {
        margin-bottom: 1.5 * $footer-toggle-height !important;
      }

      .flex-video {
        width: auto !important;
      }

      .vimeo-placeholder {
        .swipe-handle {
          display: none !important;
        }
      }
    }
  }

  //////////////////////////////////////////////////////////////////////////////
  // CARPET

  .content-box--carpet {
    .carpet {
      position: relative;
      left: auto;
      top: auto;
      right: auto;
      bottom: auto;
    }

    .carpet-item {
      opacity: 1;
      position: relative;
      transform: none;
      transition: none;
      padding-bottom: 0.5 * $base-line !important;

      img {
        max-width: 100%;
      }

      .caption {
        position: relative;
        background-color: none;
        top: 0;
        left: 0;
        padding: 0;
        //height: rem-calc($gallery-caption-height);
        height: auto !important;
        margin-bottom: 1.5 * $base-line !important;
        text-align: left;
      }

      .btn-close {
        display: none;
      }

      &,
      &.thumb,
      &.active {
        transform: none;
      }
    }
  }

  //////////////////////////////////////////////////////////////////////////////
  // TV
  .content-box--tv {
    .lightsoff-overlay {
      display: none !important;
    }
  }

  //////////////////////////////////////////////////////////////////////////////
  // STATIC PAGES

  .content-box--static-page {
    margin-bottom: 1.5 * $base-line !important;

    .image-block {
      position: relative;
      width: 100%;
    }

    .portrait-koni {
      width: 50%;
    }

    .text-block {
      margin-left: 0;
      margin-top: $base-line;
      //important to keep flexbox adapting to the height
      height: auto !important;
    }
  }

  // ---------------------------------------------------------------------------
}
//@include breakpoint(small only)
