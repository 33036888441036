@import "../app-settings";

// -----------------------------------------------------------------------------
// Gallery Strip

//OVERRIDE app settings
$gallery-content-box-max-height: 520px;

.content-box--gallery-strip {
  @extend .content-box--gallery-slider;

  //override the limit the max height
  max-height: $gallery-content-box-max-height;

  //full width
  margin: 0;

  //full width, no margins
  .gallery-slider {
    margin: 0;
    max-width: none;
  }

  .gallery-cell {
    width: auto;
    margin: 0 5px;

    &.marginless {
      margin: 0;
    }

    &:first-child {
      padding-left: 10px;
    }

    img {
      //Setting max width to none will make different image ratios full
      //BUT after a resize only a reload will fix positioning
      max-width: none;
      max-height: 100%;
    }

    .caption {
      text-align: left;
    }

    //video special
    .vimeo-placeholder {
      img {
        opacity: 0.5;
      }
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }
      .swipe-handle {
        position: absolute;
      }
      .swipe-handle-left {
        top: 0;
        left: 0;
        width: 40%;
      }
      .swipe-handle-right {
        top: 0;
        right: 0;
        width: 40%;
      }
      .swipe-handle-bottom {
        bottom: 0;
        right: 0;
        width: 100%;
        height: 45% !important;
      }
      .swipe-handle-top {
        top: 0;
        right: 0;
        width: 100%;
        height: 35% !important;
      }
    }
  }

  //override buttons
  .flickity-prev-next-button:hover,
  .flickity-prev-next-button.previous,
  .flickity-prev-next-button.previous:hover,
  .flickity-prev-next-button.next,
  .flickity-prev-next-button.next:hover {
    background-image: url("icons/arrows-white.svg") !important;
  }
}
