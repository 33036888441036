/*
* Typography
*/

$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-offcanvas: 300;
$font-family-title: "Impact", "Anton", sans-serif; //400
$font-family-sans-serif: "Open Sans", sans-serif; // 300, 400, 700

// Title font
@font-face {
  font-family: "Anton";
  font-style: normal;
  font-weight: 400;
  src:
    local("Anton"),
    url(fonts/Anton.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

%font-title {
  font-family: $font-family-title;
  font-weight: $font-weight-normal;
  font-style: normal;
  color: $secondary-color;
}

// Body font
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src:
    local("Open Sans"),
    url(fonts/OpenSans.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193,
    U+2212, U+2215, U+FEFF, U+FFFD;
}

%font-body-regular {
  font-family: $font-family-sans-serif;
  font-weight: $font-weight-normal;
  font-style: normal;
  line-height: $base-line-height;
  color: $secondary-color;
}

%font-body-bold {
  font-family: $font-family-sans-serif;
  font-weight: $font-weight-bold;
  font-style: normal;
  line-height: $base-line-height;
  color: $secondary-color;
}

%font-offcanvas {
  font-family: $font-family-sans-serif;
  font-weight: $font-weight-offcanvas;
  font-style: normal;
  line-height: $base-line-height;
  color: $secondary-color;
}

// base elements
body {
  @extend %font-body-regular;
  color: $secondary-color;
}

h1,
h2,
h3,
h4,
h5 {
  @extend %font-title;
}

h1 {
  line-height: rem-calc(60);
}

span.year {
  color: lighten($secondary-color, 50%);
  padding-right: 0.2 * $base-line;
}

h3.storytelling {
  color: lighten($secondary-color, 50%);

  a {
    color: lighten($secondary-color, 50%) !important;
  }
}

// navigation font
%font-offcanvas-navigation {
  @extend h2;
  @extend %font-offcanvas;
  font-size: rem-calc(28);

  @include breakpoint(small only) {
    font-size: rem-calc(0.8 * 28);
  }
}

.debug {
  display: none;
}

// debugging the headers
@if $DEBUG == true {
  h1:before {
    content: "[h1]";
    display: inline-block;
  }

  h2:before {
    content: "[h2]";
    display: inline-block;
  }

  h3:before {
    content: "[h3]";
    display: inline-block;
  }

  h4:before {
    content: "[H4]";
    display: inline-block;
  }

  .debug {
    position: absolute;
    left: 0;
    bottom: 0;
    display: inline-block;
    border: 1px dashed green;
    padding: $base-line;
    margin-left: $base-line;
    margin-bottom: $base-line;
    font-family: monospace;
    font-size: rem-calc(14);
    color: greenyellow;
    background-color: rgba(0, 0, 0, 0.8);
  }
}
