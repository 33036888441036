//tmp SLIDER settings

.site-content {
  background-color: $white;

  @if $DEBUG == true {
    background-color: pink;
  }
}

// this streches to container to 100% inside the flexbox child
.content-box {
  padding-bottom: rem-calc($content-box-padding-bottom);

  //used mainly for tv but maybe also others
  transition: all 0.4s linear;

  @if $DEBUG == true {
    border: 1px solid red;
  }
}
