$background-color: rgba(34, 34, 34, 0.9); //#222222

.offcanvas {
  position: fixed;
  top: 0;
  height: 100%;
  width: 250px;
  z-index: 99; // see _layout.scss for stacking
  @include breakpoint(medium up) {
    width: 400px;
  }

  background: $background-color;
  overflow-y: auto;

  //animation
  transform: translateX(-100%);
  transition: transform ease 0.25s;

  //animate in and out
  &.is-open {
    transform: translateX(0);
  }

  .main-nav,
  .meta-nav {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }

  .meta-nav {
    padding-top: rem-calc(33);
  }

  //typo styles
  a {
    display: block;
    color: $white;
    padding: 0.2rem 0 0.8rem !important;
    @extend %font-offcanvas-navigation;

    //force lowercase
    text-transform: lowercase;
  }

  ul li {
    &:first-child {
      border-top: none;
    }

    &:last-child {
      border-bottom: 1px solid $white;
    }

    border-top: 1px solid $white;
    margin: 0 20px;

    li,
    li:first-child {
      border-top: 1px dotted $white;
      margin-right: 0;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  // F6 ovrrrides
  .is-accordion-submenu-parent > a::after {
    border-color: $white transparent transparent;
  }
}

// the blocker when the navigation is active
.off-canvas-blocker {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 98; // see _layout.scss for stacking
  cursor: pointer;
  background-color: $off-canvas-blocker-color;
  //opacity: $off-canvas-blocker-opacity;
}
