$retina: "only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx)";

.kn-icons {
  white-space: nowrap;
  //transform: scale3d(0.75, 0.75, 1);
  //transform-origin: 100% 30%;
}

.kn-icons .kn-icon {
  display: inline-block;
  margin-right: 10px;
}

.kn-icon {
  display: block;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}

.kn-icon-menu {
  /*
  background-image: url('icons/icon_menu.svg');
  width: 60px;
  height: 60px;
  background-size: 60px 60px;
  */
  background-image: url("icons/btn-reto/menu_button_45x45.png");
  @media #{$retina} {
    background-image: url("icons/btn-reto/menu_button_90x90.png");
  }
  width: 45px;
  height: 45px;
  background-size: 45px 45px;
}

.kn-icon-close {
  /*
  background-image: url('icons/icon_close.svg');
  width: 60px;
  height: 60px;
  background-size: 60px 60px;
  */
  background-image: url("icons/btn-reto/close_button_45x45.png");
  @media #{$retina} {
    background-image: url("icons/btn-reto/close_button_90x90.png");
  }
  width: 45px;
  height: 45px;
  background-size: 45px 45px;
}

.kn-icon-back {
  background-image: url("icons/icon_back.svg");
  width: 60px;
  height: 60px;
  background-size: 60px 60px;
}

%kn-icons-footer {
  background-image: url("icons/icons_footer.svg");
  .ie & {
    background-image: url("icons/icons_footer.svg.png");
  }

  width: 30px;
  height: 30px;
  background-size: 310px 30px;

  &.highlight {
    background-image: url("icons/icons_footer-hi.svg");
    .ie & {
      background-image: url("icons/icons_footer-hi.svg.png");
    }
    width: 38px;
    height: 38px;
    background-size: 318px 38px;
  }
}

.kn-icon-soundplayer {
  @extend %kn-icons-footer;

  background-position: -40px 0px;

  &.is-playing {
    background-position: 0px 0px;
  }
}

.kn-icon-pdf {
  @extend %kn-icons-footer;
  background-position: -80px 0px;
}

.kn-icon-share {
  @extend %kn-icons-footer;
  background-position: -120px 0px;
}

.kn-icon-lang-en {
  @extend %kn-icons-footer;
  background-position: -160px 0px;
}

.kn-icon-lang-de {
  @extend %kn-icons-footer;
  background-position: -200px 0px;
}

.kn-icon-tearsheet-open {
  @extend %kn-icons-footer;
  background-position: -240px 0px;

  &.highlight {
    margin-top: -4px;
    margin-right: -4px;
  }
}

.kn-icon-tearsheet-close {
  @extend %kn-icons-footer;
  background-position: -280px 0px;
}

.kn-icon-instagram {
  // @extend %kn-icons-footer;
  border-radius: 50%;
  width: 30px;
  height: 30px;

  border: 1px solid $black;
  background: url("icons/instagram.svg") center / 65% no-repeat;
  background-color: $white;
}

%kn-arrows {
  background-image: url("icons/arrows-black.svg");
  width: 32px;
  height: 32px;
  display: block;
  background-size: 90px 90px;

  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  background-repeat: no-repeat;
}

.kn-arrow-up-down {
  @extend %kn-arrows;
  background-position: -29px -66px; //-(45-16)px -(90-32)px;

  &.arrow-up {
    background-position: -29px 8px; //45 - 16
  }
}

//overwrite flickity
.flickity-prev-next-button {
  width: 32px;
  height: 32px;

  &:hover {
    background-image: url("icons/arrows-black.svg");
    background-size: 90px 90px;
    background-repeat: no-repeat;
  }

  & > svg {
    display: none;
  }
}

.flickity-prev-next-button.previous,
.flickity-prev-next-button.previous:hover {
  @extend %kn-arrows;
  background-position: 8px -29px;
}

.flickity-prev-next-button.next,
.flickity-prev-next-button.next:hover {
  @extend %kn-arrows;
  background-position: -66px -29px;
}
