////////////////////////////////////////////////////////////////////////////////
// global settings

// Show debugging layout
$DEBUG: false;

//Base metrics
$base-line: 1rem;
$base-line-height: 1.5;

////////////////////////////////////////////////////////////////////////////////
// foundation colors
$foundation-palette: (
  primary: #e86b37,
  secondary: #000,
  success: #3adb76,
  warning: #ffae00,
  alert: #ec5840,
);

// prettier-ignore
$header-sizes: (
  small: (
    "h1": 30,
    "h2": 20,
    "h3": 19,
    "h4": 18,
    "h5": 17,
    "h6": 16,
  ),
  medium: (
    "h1": 46,
    // OK
    "h2": 60,
    "h3": 23,
    // OK
    "h4": 60,
    "h5": 60,
    "h6": 14,
    // OK
  ),
);

////////////////////////////////////////////////////////////////////////////////
// base elments

// Header
$header-height: 130px;
$header-height-smallscreens: $header-height + 25px;
$header-padding-top: 17px; //calculates to 130;
$header-title-margin-large: 0 10px 0 50px;
$header-title-margin-medium: 0 10px 0 30px;
$header-title-margin-small: 0 10px 0 10px;

// Off-canvas navigation
$off-canvas-blocker-color: transparent;
//$off-canvas-blocker-opacity : 0.2;

// content-box
$content-box-padding-bottom: 60px;

// Footer
$footer-toggle-height: 50px;

////////////////////////////////////////////////////////////////////////////////
// Global Gallery Settings

$gallery-caption-height: 50px;
$gallery-content-box-max-height: 500px; //500 this is the default

$gallery-thumb-index-max-height: 60px;

////////////////////////////////////////////////////////////////////////////////
//VIEWS

// Startpage
$cite-box-width: 504px; //orgiginal 420, new + 20%
$cite-box-height: 270px - $header-height;

$strip-label-background-color: #aaa;

// for the timeline we use F6-slider, which is slightly confusing from a
// naming pov.
//F6-slider settings overrides
$slider-height: 0;

$slider-handle-hover-offset: 3px;
$slider-handle-height: 60px + 2px + (2 * $slider-handle-hover-offset); //2 x 1px border
$slider-handle-width: 60px + 2px + (2 * $slider-handle-hover-offset); //2 x 1px border
$slider-handle-background: #ff0000;
$slider-radius: 100%;

// Carptet
//!important! HAS TO BE SYNCED WITH carpet.js::13
$carpet-scale-factor: 0.4;
