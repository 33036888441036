// Put here FLICKITY GALLERY global overrides

.flickity-prev-next-button,
.flickity-prev-next-button:hover,
.flickity-prev-next-button:disabled {
  background: none;

  @include breakpoint(medium only) {
    opacity: 0;
    transition: opacity 0.2s linear;

    .project-slider:hover & {
      opacity: 1;

      /*
      //this was removed, reinsert it for disabled buttons
      &:disabled {
        opacity: 0.3;
      }
      */
    }
  }

  &.previous {
    margin-left: 30px;

    &:disabled {
      display: none;
    }
  }

  &.next {
    margin-right: 30px;

    &:disabled {
      display: none;
    }
  }
}
