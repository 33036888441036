$color-closed: #222222;
$color-open: #e9ede9;

footer {
  position: relative;
  height: $footer-toggle-height;
}

//TODO move to settings
$drawer-change-time: 0.2s;

.footer {
  position: absolute;
  width: 100%;
  z-index: 90; // see _layout.scss for stacking
  transform: translateY(0%);
  transition: all $drawer-change-time ease-in-out;

  // the moving part
  .drawer-wrapper {
    display: flex;
    flex-direction: column;

    opacity: 0.9;

    background-color: $color-closed;
    transition: background-color $drawer-change-time linear;
  }

  .toggle {
    flex: none;
    width: $footer-toggle-height;
    height: $footer-toggle-height;
    margin: 0 auto;

    //show no focus
    &:focus {
      outline: 0;
    }
  }

  .description {
    flex: auto;
    width: 400px;
    margin-bottom: $footer-toggle-height;
    min-height: $footer-toggle-height;

    h3,
    p {
      //color: $secondary-color;
      margin-bottom: 0;
    }

    p {
      font-size: rem-calc(14);
    }
  }

  //open state
  &.is-open {
    transform: translateY(-100%);

    //fixing the footer bar spacing. just append some block after
    &:after {
      display: block;
      position: absolute;
      content: "";
      background: $color-open;
      width: 100%;
      height: $footer-toggle-height;
    }

    .drawer-wrapper {
      background-color: $color-open;
    }
  }
}

// util nav
.util-nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: $footer-toggle-height;
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 91; //see layout.scss

  .kn-icon {
    margin-right: 10px;
  }

  .pdf-download {
    &.inactive {
      display: none;
    }
  }

  .pdf-download-overlay {
    position: absolute;
    border: 1px solid $secondary-color;
    background-color: $white;
    padding: 0.5 * $base-line $base-line;
    bottom: 55px;
    right: 10px;
    width: 300px;
    display: none;

    //using tearsheet icon as close button
    .close-overlay {
      position: absolute;
      right: 0;
      top: 0.5 * $base-line;
    }

    form {
    }

    p,
    input,
    button {
      font-size: rem-calc(13);
      margin-bottom: 0.5 * $base-line;
    }

    input {
      width: 80%;
      border: 1px solid $secondary-color;
      padding: rem-calc(4);

      &.error {
        border-color: $primary-color;
      }
    }

    button {
      text-transform: uppercase;
    }

    &.reveal {
      display: block;
    }
  }

  //soundplayer
  //audio tag has to have no dim for (mobile)safari
  #soundplayer {
    width: 0;
    height: 0;
  }
  .soundplayer {
    &.inactive {
      display: none;
    }
  }

  //sharing

  .sharing {
    position: relative;
  }

  #shariff-drawer {
    display: none;
    position: absolute;
    left: -1px;
    bottom: 40px;

    &.is-open {
      display: block;
    }
  }

  .shariff {
    .shariff-button {
      //this was once .selection
      cursor: pointer;
      width: 30px;
      height: 30px;
      border: 1px solid transparent;
      border-radius: 100%;
      background-color: $white;
      margin-right: 10px;
      font-size: rem-calc(13);

      border: none;

      .fa {
        font-size: rem-calc($base-line);

        @include breakpoint(medium up) {
          width: 30px;
          line-height: 30px;
        }

        &.fa-facebook {
          margin-left: -1px;
        }

        &.fa-linkedin {
          margin-top: -1px;
        }
      }
    }

    .share_text {
      display: none;
    }
  }
}
