//foundation
@import "./foundation";

//carousel
@import "flickity/dist/flickity";

// custom
@import "extensions";
@import "typography";
@import "icons";
@import "layout";
@import "header";
@import "offcanvas";
@import "content";
@import "footer";

//content types (not python ones)
@import "./flickity-overrides";
@import "contents/index";
@import "contents/gallery";
@import "contents/gallery-index";
@import "contents/gallery-strip";
@import "contents/carpet";
@import "contents/tv";
@import "contents/static-pages";
@import "shariff/dist/shariff.complete.css";

//overrides for small screen galleries
@import "contents/smallscreens";

@import "f3cc";

a:focus {
  outline: 0;
}
