.site-margin {
  margin: $header-title-margin-small;

  @include breakpoint(medium only) {
    margin: $header-title-margin-medium;
  }

  @include breakpoint(large up) {
    margin: $header-title-margin-large;
  }
}

/*
.centered-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
*/

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
