.header {
  height: $header-height;
  display: flex;
  justify-content: space-between;

  padding-top: $header-padding-top; //calculates to 20;

  .site-title {
    text-transform: lowercase;

    a {
      color: $secondary-color;
    }

    .delimiter {
      @extend %font-body-regular;
      line-height: 0;
    }

    h1 {
      margin-bottom: 0;
    }

    h1,
    h3 {
      position: relative;
      z-index: 90; // see _layout.scss for stacking
    }

    .header-head {
      h1,
      h3 {
        display: inline-block;
      }
      h1 {
        padding-right: $base-line;
      }
    }
  }

  .header-project {
    opacity: 1;
    //on footer close we have a little delay to guide the eye
    transition: opacity 0.3s 0.3s linear;

    &.footer-is-open {
      opacity: 0;
      //on footer open we just fade it immediately out
      transition: opacity 0.3s 0s linear;
    }
  }

  .kn-icons {
    padding-top: 8px;
  }

  .kn-icon-menu {
    margin-right: 20px;
  }

  .kn-icons,
  .kn-icon-menu,
  .kn-icon-close {
    @include breakpoint(medium up) {
      z-index: 90; // see _layout.scss for stacking
    }
  }
}
