// time strip
.time-strip {
  position: relative;
  height: 60px;
  margin-top: 30px;

  //prevent selecting on safari, cursor still is text cursor with css
  user-select: none;

  ul {
    position: absolute;
    bottom: 0;
    list-style: none;
    margin: 0;
    width: 100%;
    display: flex;
  }

  li {
    //make em all the same size even when resizing
    flex: 1 1 0px; // see https://github.com/philipwalton/flexbugs#4-flex-shorthand-declarations-with-unitless-flex-basis-values-are-ignored
    height: 20px;

    // the lines
    div {
      width: 20%;
      height: 20px;
      display: inline-block;
      border-left: 1px solid $secondary-color;
    }

    // the labels
    span {
      position: absolute;
      font-size: rem-calc(14);
      text-indent: 6px;
      line-height: 1;

      transform: rotate(-90deg);
      transform-origin: 0 0;
    }
  } //li

  //F6 ovrrides
  .slider {
    margin: 0;
    margin-top: -1px;

    .slider-handle {
      opacity: 0.7;
      padding: $slider-handle-hover-offset;
      background-clip: content-box;
      background-color: $slider-handle-background;
      border: 1px solid transparent;
      margin-top: -($slider-handle-hover-offset + 1px); //1px border

      &:hover {
        color: white;
        border: 1px solid $slider-handle-background;
      }

      &:focus {
        outline: 0;
      }
      // F6 has no prefixed tranform which gives a problem on ios.
      // but we need no transform anyway, so reset it (no prefixer...)
      transform: none;
    }
  }

  @if $DEBUG == true {
    border: 1px solid yellow;
  }
}
