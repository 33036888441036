///////////////////////////////////////////////////////////////////////////////
// Citation

.content-box--citation {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  opacity: 0;

  height: $cite-box-height;
  padding-right: 60px + 20px; //margin and icon width

  position: relative;
  z-index: 98;
  pointer-events: none;

  div {
    line-height: $base-line-height;
    font-size: 0.9em;
  }

  .cite-text {
    width: $cite-box-width;
    flex: none;
    padding-bottom: $base-line / 2;

    @if $DEBUG == true {
      border: 1px solid blue;
    }
  }

  .cite-person {
    width: $cite-box-width;
    flex: 1;
    font-size: 0.8em;

    @if $DEBUG == true {
      border: 1px solid blue;
    }
  }
}

///////////////////////////////////////////////////////////////////////////////
// Project slider

.content-box--project-slider {
  height: 200px;

  .project-slider {
    height: 100%;
    width: 100%;

    .flickity-viewport {
      height: 100%;
    }

    @if $DEBUG == true {
      border: 1px solid green;
    }
  }

  .slider-cell {
    height: 100%;
    padding: 0 5px;

    //layout all the same
    //display: flex;
    //flex-direction: column;

    img {
      //IMPORTANT override foundation or cells will collapse
      max-width: none;
      max-height: 100%;
      display: block;
      width: 260px;
    }

    .visual {
      //flex: 1 auto;
      height: 170px;
      display: flex;
      flex-direction: column;
      justify-content: center; //or flex end
    }

    .label {
      //flex: none;
      height: 30px;
      line-height: 30px;
      background-color: $strip-label-background-color;
      @extend h6;
      @extend %font-body-bold;
      text-indent: 0.25 * $base-line;

      color: $white;
      margin-bottom: 0;

      position: absolute;
      width: 100%;

      date {
        @extend %font-body-regular;
        color: $white;
        padding-right: 0.5 * $base-line;
      }
    }

    @if $DEBUG == true {
      border: 1px solid red;
    }
  }
}

.project-slider,
.time-strip {
  opacity: 1;
  transition: opacity 0.5s linear;
  .loading-in-progress {
    opacity: 0;
  }
}

///////////////////////////////////////////////////////////////////////////////
//OVERRIDE footer toggling for the start page.
.footer.lockdown {
  &.is-open {
    transform: translateY(0%);
    .drawer-wrapper {
      background-color: $black;
    }
  }

  button.toggle {
    display: none;
  }
}

.loading-in-progress {
  opacity: 0;
  transition: opacity 0.5s linear;
}

/*
//INITIAL REVEALING
.content-box--project-slider,
.content-box--project-timestrip {
  opacity: 1;
  transition: opacity 0.5s linear;
  &.inactive {
    opacity: 0;
  }
}
*/

///////////////////////////////////////////////////////////////////////////////
// Timestrip

.content-box--project-timestrip {
  @import "partials/_timestrip";
}
