@import "../app-settings";

// -----------------------------------------------------------------------------
// Gallery with Thumbnails

//OVERRIDE app settings
$gallery-content-box-max-height: 550px;

.content-box--gallery-index {
  @extend .content-box--gallery-slider;

  //override the limit the max height
  max-height: $gallery-content-box-max-height;
}

// -----------------------------------------------------------------------------
//THUMBNAIL SLIDER
.content-box--gallery-index-thumbs {
  @extend .content-box--gallery-slider;

  margin: 0;
  max-height: $gallery-thumb-index-max-height;

  .slider-cell {
    margin: 0 5px;

    img {
      //Setting max width to none will make different image ratios full
      max-width: none;
    }
  }

  @if $DEBUG == true {
    border: 1px solid fuchsia;
  }
}
