/* one page will rule them all */

// Z-Index Stacking:
/*
  1: Slider-handle (Foundation intern)
  80: Nav-Toggle (small only)
  90: Footer drawer + header logo + header h3 + nav toggle medium + large
  98: Off-canvas-blocker
  99: Off-canvas-navigation

*/

html,
body {
  width: 100%;
  height: 100%;

  @include breakpoint(medium up) {
    max-height: 100%;
    position: fixed;
  }
}

body {
  @include breakpoint(medium up) {
    display: flex;
    flex-direction: column;
  }
}

header,
footer {
  @include breakpoint(medium up) {
    flex: none;
  }
}

.site-content {
  width: 100%;
  position: relative;

  //medium up uses flex
  @include breakpoint(medium up) {
    flex: 1 1 auto; /* 2 */

    //fixing chrome scroll bug
    //http://stackoverflow.com/questions/14962468/flexbox-and-vertical-scroll-in-a-full-height-app-using-newer-flexbox-api/14964944#14964944
    /* overflow-y: auto; */
    min-height: 0px;
  }
}

.content-box {
  width: 100%;
  height: 100%;
  overflow-y: auto;

  @include breakpoint(medium up) {
    position: absolute;
  }
}
